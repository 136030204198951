<template>
  <v-container fluid grey lighten-3 pa-10>
    <v-row class="justify-center">
      <v-col>
        <v-card class="mx-auto" max-width="450">
          <v-card-title>แก้ไขข้อมูล User</v-card-title>
          <v-card-text>
            <v-form v-model="valid" ref="form" @submit.prevent="submit">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.username"
                      :rules="nameRules"
                      :counter="50"
                      label="username"
                      id="username"
                      maxlength="50"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                       <v-select
                       v-model="item.level"
                      :items="levelSelect"
                      :rules="[v => !!v || 'กรุณาเลือก']"
                      label="กรุณาเลือก Permission"
                      @change="(val) => { item.level = val}"
                    ></v-select>
                  </v-col>

                  <v-col cols="12">
                    <v-switch
                      v-model="item.active"
                      label="active"
                      color="success"
                      input-value="1"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-divider class="mt-5 mb-5"></v-divider>

                <v-card-title>Change password</v-card-title>

                <v-col cols="12">
                    <v-text-field
                      v-model="item.newpassword"
                      :rules="passwordRules"
                      :counter="30"
                      label="รหัสผ่านใหม่"
                      id="newpassword"
                      maxlength="30"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="item.renewpassword"
                      :rules="repasswordRules"
                      :counter="30"
                      label="กรอกรหัสผ่านใหม่อีกครั้ง"
                      id="renewpassword"
                      maxlength="30"
                    ></v-text-field>
                  </v-col>


                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn color="gray" class="mr-5" @click="cancel"
                    >ยกเลิก</v-btn
                  >
                  <v-btn :disabled="!valid" color="success" type="submit"
                    >บันทึก</v-btn
                  >
                </v-row>

                <v-row class="px-3 pt-5 justify-center">
                <span class="red--text">{{msgResponse}}</span>
                </v-row>

              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/services/api";

export default {
  name: "UserEdit",
  data() {
    return {
      levelSelect: ['normal', 'admin'],
      item: {
        username: "",
        oldUsername: "",
        level: "",
        password: "",
        newpassword: "",
        renewpassword: "",
        active: 0
      },
      nameRules: [
        v => !!v || "Username is required",
        v => !!v || "Username is required", v => v.length > 4 || 'Username must be more than or equal 5 chars'
      ],
      passwordRules: [ (v) => {
          if (v != '') {
              return /^[A-Za-z\d]{8,}$/.test(v) || "Password must be more than or equal 8 chars"
          } else {
              return true;
          }
        } 
      ],
      repasswordRules: [ (v) => {
         if (v != this.item.newpassword) return false;
         else return true;
        }
      ],
      valid: true,
      msgResponse: ""
    };
  },
  methods: {
    async submit() {
      //alert(JSON.stringify(this.item))
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("id", this.item.id);
        formData.append("username", this.item.username);
        formData.append("oldUsername", this.item.oldUsername);
        formData.append("level", this.item.level);
        formData.append("active", this.item.active ? 1 : 0);
        if (typeof this.item.newpassword != 'undefined') {
            formData.append("password", this.item.newpassword);
        }
        let result = await api.updateUser(formData);
        console.log(result.data)
        if (result.data.result == 'nok') {
            this.msgResponse = result.data.message;
        } else {
            this.$router.push("/user");
        }
      }
    },
    cancel() {
      this.$router.push("/user");
    },
  },
  async mounted() {
    let result = await api.getUserById(this.$route.params.id);
    this.item = result.data;
    this.item.oldUsername = result.data.username; // for check exists username in database

  },
  computed: {}
};
</script>

<style></style>
